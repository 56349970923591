<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow mb-5 pb-5"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded mb-3">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/login"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                  style="max-height: 36px"
                />
              </span>
              <h2 class="brand-text text-uppercase">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <!-- <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li> -->
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import {
  provide, computed, ref, onUnmounted,
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'
import store from '@/store'
import schoolDashesStoreModule from '@/views/admin/settings/menu-shortcuts/schoolDashesStoreModule'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      navItems: navMenuItems,
    }
  },
  mounted() {
    // push additional menu elements (from backend)
    const userData = JSON.parse(localStorage.getItem('userData'))
    store.dispatch('app-school-dashes/menuFetch', { role: userData.role, type: 'menus' })
        .then(response => {
          if (response.data) {
            // console.log(response.data.menus)
            const menusArr = response.data.menus
            menusArr.sort((a, b) => {
              return parseInt(a.order, 0) - parseInt(b.order, 0)
            })
            menusArr.forEach(menu => {
              this.navItems.push(menu)
            })
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.error('api endpoint not found')
          }
        })
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const itemsNav = navMenuItems.map(el => {
      if (typeof el.children !== 'undefined') {
        let childs = el.children
        childs = el.children.filter(elm => {
          // @todo: admin role should be assigned dynamically from localstorage
          if (typeof elm.roles !== 'undefined' && elm.roles.includes('admin') && userData.role === 'administrator') {
            return elm
          }
          if (typeof elm.roles !== 'undefined' && elm.roles.includes('company') && userData.role === 'company') {
            return elm
          }
          if (typeof elm.roles !== 'undefined' && elm.roles.includes('student') && userData.role === 'student') {
            return elm
          }
          if (typeof elm.roles !== 'undefined' && elm.roles.includes('teacher') && userData.role === 'teacher') {
            return elm
          }
          if (typeof elm.roles !== 'undefined' && elm.roles.includes('agent') && userData.role === 'agent') {
            return elm
          }
          return false
        })
        /* eslint-disable no-param-reassign */
        el.children = childs
      }
      return el
    })
    this.navItems = itemsNav
  },
  setup(props) {
    const SCHOOL_DASHES_STORE_MODULE_NAME = 'app-school-dashes'
    // Register module
    if (!store.hasModule(SCHOOL_DASHES_STORE_MODULE_NAME)) store.registerModule(SCHOOL_DASHES_STORE_MODULE_NAME, schoolDashesStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCHOOL_DASHES_STORE_MODULE_NAME)) store.unregisterModule(SCHOOL_DASHES_STORE_MODULE_NAME)
    })

    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)
    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
