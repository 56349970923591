import axios from '@axios'

export default {
  namespaced: true,
  state: {
    data: {
      menus: null,
      widgets: null,
      menuSettings: null,
    },
  },
  /* eslint-disable camelcase */
  getters: {},
  mutations: {
    setSettings(state, payload) {
      state.data.widgets = payload.widgets
      state.data.menus = payload.menus
    },
    setMenuSettings(state, payload) {
      state.data.menuSettings = payload
    },
  },
  actions: {
    fetch(store) {
      return new Promise((resolve, reject) => {
        axios
          .get('/school-widgets-menus')
          .then(response => {
            store.commit('setSettings', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    menuFetch(store, { role, type }) { // from src/@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue
      return new Promise((resolve, reject) => {
        axios
            .get(`/school-widgets-menus/${type}/${role}`)
            .then(response => {
              store.commit('setMenuSettings', response.data)
              resolve(response)
            })
            .catch(error => reject(error))
      })
    },
    update(store, settings) {
      store.commit('setSettings', settings)
      return new Promise((resolve, reject) => {
        axios
            .put('/school-widgets-menus', settings)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
