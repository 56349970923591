export default [
  {
    title: 'Teachers',
    icon: 'AwardIcon',
    noCollapsible: false,
    children: [
      {
        title: 'List',
        route: 'admin-teachers-list',
        icon: 'UsersIcon',
        roles: ['admin'],
      },
      // {
      //   title: 'Teachers',
      //   route: 'apps-users-list-teacher',
      //   roles: ['admin'],
      // },
      {
        title: 'Calendar',
        route: 'admin-teacher-calendar',
        icon: 'ClipboardIcon',
        roles: ['admin'],
      },
      {
        title: 'Days off',
        route: 'admin-teacher-events',
        icon: 'ClipboardIcon',
        roles: ['admin'],
      },
      // {
      //   title: 'Courses approval',
      //   route: 'admin-course-approval',
      //   // icon: 'ClipboardIcon',
      //   roles: ['admin'],
      // },
      // {
      //   title: 'Teachers substitutions',
      //   route: 'admin-courses-substitutions',
      //   icon: 'BookIcon',
      //   roles: ['admin'],
      // },
    ],
  },
  {
    title: 'Students',
    icon: 'UserIcon',
    children: [
      {
        title: 'List',
        route: 'apps-users-list-student',
        roles: ['admin'],
      },
      {
        title: 'Registrations list',
        route: 'registrations',
        icon: 'BookOpenIcon',
        roles: ['admin'],
      },
      {
        title: 'Abandoned checkout',
        route: 'registrations-unfulfilled',
        icon: 'BookOpenIcon',
        roles: ['admin'],
      },
      {
        title: 'Payment',
        route: 'admin-invoices',
        icon: 'ClipboardIcon',
        roles: ['admin'],
      },
      {
        title: 'Withdrawal',
        route: 'admin-resignations-list',
        icon: 'FileTextIcon',
        roles: ['admin'],
      },
      {
        title: 'Claims list',
        route: 'admin-claim-list',
        icon: 'FileTextIcon',
        roles: ['admin'],
      },
    ],
  },
  // {
  //   title: 'Statistics',
  //   icon: 'PieChartIcon',
  //   children: [
  //     {
  //       title: 'Courses/students',
  //       route: 'admin-statistics',
  //       // icon: 'MessageCircleIcon',
  //       roles: ['admin'],
  //     },
  //     {
  //       title: 'Teachers',
  //       route: 'admin-teacher-statistics',
  //       // icon: 'MailIcon',
  //       roles: ['admin'],
  //     },
  //     {
  //       title: 'Courses & Registrations',
  //       route: 'admin-courses-registrations',
  //       // icon: 'MailIcon',
  //       roles: ['admin'],
  //     },
  //   ],
  // },
  // {
  //   title: 'Messaging',
  //   icon: 'MessageSquareIcon',
  //   children: [
  //     // {
  //     //   title: 'Chat',
  //     //   route: 'apps-chat',
  //     //   icon: 'MessageCircleIcon',
  //     //   roles: ['admin', 'teacher', 'student'],
  //     // },
  //     {
  //       title: 'Support center',
  //       route: 'apps-support-center',
  //       icon: 'HeadphonesIcon',
  //       roles: ['admin', 'student'],
  //     },
  //     // {
  //     //   title: 'Inbox',
  //     //   route: 'apps-email',
  //     //   icon: 'MailIcon',
  //     //   roles: ['admin', 'teacher', 'student'],
  //     // },
  //   ],
  // },
  {
    title: 'Administration',
    icon: 'ShieldIcon',
    children: [
      {
        title: 'List',
        route: 'apps-users-list-administrator',
        roles: ['admin'],
      },
      {
        title: 'Attendance list',
        route: 'admin-attendance-calendar',
        icon: 'ClipboardIcon',
        roles: ['admin'],
      },
      {
        title: 'Admin calendar',
        route: 'admin-admin-calendar',
        icon: 'ClipboardIcon',
        roles: ['admin'],
      },
      {
        title: 'Days off calendar',
        route: 'admin-day-off-calendar',
        icon: 'ClipboardIcon',
        roles: ['admin'],
      },
      {
        title: 'Classrooms list',
        route: 'admin-classrooms-list',
        icon: 'FileTextIcon',
        roles: ['admin'],
      },
      {
        title: 'Classrooms Calendar',
        route: 'admin-classroom-calendar',
        icon: 'ClipboardIcon',
        roles: ['admin'],
      },
      // {
      //   title: 'Cities list',
      //   route: 'admin-cities-list',
      //   icon: 'MapPinIcon',
      //   roles: ['admin'],
      // },
      // {
      //   title: 'Reviews list',
      //   route: 'admin-reviews',
      //   roles: ['admin'],
      // },
      {
        title: 'Events list',
        route: 'admin-events-list',
        icon: 'FileTextIcon',
        roles: ['admin'],
      },
      {
        title: 'Emails Template',
        route: 'admin-emails',
        icon: 'FileTextIcon',
        roles: ['admin'],
      },
      {
        title: 'Settings',
        route: 'notification-setting',
        icon: 'FileTextIcon',
        roles: ['admin'],
      },
      {
        title: 'Approval history',
        route: 'admin-approval-history',
        roles: ['admin'],
      },
      {
        title: 'Activity log',
        route: 'admin-activities',
        icon: 'FileTextIcon',
        roles: ['admin'],
      },
    ],
  },
  {
    title: 'Settings',
    icon: 'ShieldIcon',
    children: [
      {
        title: 'School Settings',
        route: 'admin-school-settings',
        icon: 'FileTextIcon',
        roles: ['admin'],
      },
      {
        title: 'Dashboards settings',
        route: 'admin-school-settings-dashes',
        icon: 'FileTextIcon',
        roles: ['admin'],
      },
    ],
  },
]
